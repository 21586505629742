<template>
  <main>
    <assets-force-card class="mb-4"></assets-force-card>
    <assets-table-card></assets-table-card>
  </main>
</template>

<script>
import AssetsTableCard from '@/views/admin/assets/components/AssetsTableCard'
import AssetsForceCard from '@/views/admin/assets/components/AssetsForceCard'

export default {
  components: {
    AssetsTableCard,
    AssetsForceCard,
  },
}
</script>
