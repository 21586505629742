var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('div',[_vm._v(" Full Assets List "),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","disabled":!_vm.isAuthenticated || _vm.assets.loading},on:{"click":_vm.getAssets}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiReload))])],1)],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"disabled":"","footer-props":{
        'items-per-page-options': [10, 10],
        'disable-items-per-page': true,
        'disable-pagination': _vm.assets.loading
      },"items":_vm.assets.list,"options":_vm.assets.options,"page":_vm.assets.meta.page,"server-items-length":_vm.assets.meta.total || 0,"loading":_vm.assets.loading,"no-data-text":'No data available',"loading-text":'Loading, pls wait'},on:{"update:options":function($event){return _vm.$set(_vm.assets, "options", $event)},"pagination":function($event){return _vm.changePagination($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))]),(item.review && item.review.message && item.status === 'review-failed')?_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","dark":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiInformation))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.review.message)+"p")])]):_vm._e()]}},{key:"item.category",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCategoryAlias(item))+" ")]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeAlias(item))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.editAsset(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiFileEdit))]),_c('span',[_vm._v("Edit Assets")])],1)],1),(['draft', 'review-failed'].includes(item.status) && item.type === 'custom')?_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.submitForReview(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiBugCheckOutline))]),_c('span',[_vm._v("Submit for Review")])],1)],1):_vm._e()],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }