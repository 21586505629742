<template>
  <v-card flat>
    <v-card-title>
      <div>
        Full Assets List

        <v-btn icon class="ml-3" @click="getAssets" :disabled="!isAuthenticated || assets.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="tableColumns"
        disabled
        :footer-props="{
          'items-per-page-options': [10, 10],
          'disable-items-per-page': true,
          'disable-pagination': assets.loading
        }"
        :items="assets.list"
        :options.sync="assets.options"
        :page="assets.meta.page"
        :server-items-length="assets.meta.total || 0"
        :loading="assets.loading"
        @pagination="changePagination($event)"
        :no-data-text="'No data available'"
        :loading-text="'Loading, pls wait'"
        class="text-no-wrap"
      >
        <template #[`item.createdAt`]="{item}">
          {{ formatDate(item.createdAt) }}
        </template>

        <template #[`item.status`]="{item}">
          <span>{{item.status}}</span>
          <v-tooltip
            bottom color="error"
            v-if="item.review && item.review.message && item.status === 'review-failed'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="error"
                class="ml-2"
                dark icon small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>{{icons.mdiInformation}}</v-icon>
              </v-btn>
            </template>
            <span>{{item.review.message}}p</span>
          </v-tooltip>
        </template>

        <template #[`item.category`]="{item}">
          {{ getCategoryAlias(item) }}
        </template>

        <template #[`item.type`]="{item}">
          {{ getTypeAlias(item) }}
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link dense @click="editAsset(item)">
                <v-list-item-title>
                  <v-icon size="18" class="me-2">{{ icons.mdiFileEdit }}</v-icon>
                  <span>Edit Assets</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link dense
                @click="submitForReview(item)"
                v-if="['draft', 'review-failed'].includes(item.status) && item.type === 'custom'"
              >
                <v-list-item-title>
                  <v-icon size="18" class="me-2">{{ icons.mdiBugCheckOutline }}</v-icon>
                  <span>Submit for Review</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiReload, mdiFileEdit, mdiDotsVertical, mdiBugCheckOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'

import { eventBus } from '@/utils/eventBus'

export default {
  data: () => ({
    icons: {
      mdiReload,
      mdiFileEdit,
      mdiDotsVertical,
      mdiBugCheckOutline,
    },
    assets: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  created() {
    eventBus.$on('asset-builder-update', () => {
      this.getAssets({ page: 1 })
    })
  },
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    tableColumns() {
      return [
        { text: 'OWNER', value: 'owner.wallet', sortable: false },
        { text: 'ASSET', value: 'name', sortable: false },
        { text: 'CATEGORY', value: 'category', sortable: false },
        { text: 'TYPE', value: 'type', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'CONTRACT', value: 'contract.address', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ]
    },
  },
  methods: {
    getAssets(query) {
      if (!this.isAuthenticated) return

      this.assets.loading = true

      const params = {
        limit: this.assets.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('admin/assets', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.assets.meta = meta
          this.assets.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.assets.loading = false
          })
        })
    },

    changePagination(pagination) {
      this.getAssets({
        page: pagination.page,
      })
    },

    getCategoryAlias(item) {
      const aliases = {
        nft: 'NFT Collection',
        whitelist: 'Whitelist',
        token: 'Token',
      }

      return aliases[item.category]
    },

    getTypeAlias(item) {
      const aliases = {
        custom: 'Custom Contract',
        internal: 'Internal Contract (NFTIZ)',
      }

      return aliases[item.type]
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },

    editAsset(item) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'asset-builder',
        modalAttrs: {
          persistent: true,
          'max-width': 1200,
        },
        componentAttrs: {
          offers: [],
          adminView: true,
          assetData: JSON.parse(JSON.stringify(item)),
        },
      })
    },

    submitForReview(item) {
      if (this.loading) return

      this.loading = true

      this.$http
        .put(`/asset/${item._id}/review`)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Asset successfully saved!`,
              },
              { root: true },
            )
          }

          this.getAssets({ page: 1 })
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
