<template>
  <v-card>
    <v-card-title>Force Run Asset Loopers</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-alert
            elevation="0"
            border="left"
            color="warning"
            class="mb-0"
          >
            This feature allows the administrator to manually run loopers to quickly check for new entries on the blockchain. <br>
            <span class="font-weight-bold">PLEASE DO NOT USE THIS TOO FREQUENTLY. ONLY IN CASE OF EXTREME NECESSITY!</span>
          </v-alert>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            label="Looper Type"
            v-model="type"
            :items="types"
            :disabled="loading"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            label="Asset ID"
            value="All assets"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
            class="w-100"
            color="primary"
            :loading="loading"
            :disabled="disabled"
            @click="forceRun"
          >Force Run</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    types: ['mint', 'whitelist'],
    type: 'mint',
    disabled: false,
    loading: false,
  }),
  methods: {
    forceRun() {
      if (this.loading) return

      this.loading = true
      this.disabled = true

      this.$http
        .post(`looper/${this.type}/force-run`)
        .then(({ data }) => {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'success',
              message: `Looper launched successfully!`,
            },
            { root: true },
          )
        })
        .catch(err => {
          this.$store.dispatch('notification/GENERATE_NOTIFICATION', { type: 'error' }, { root: true })
        })
        .finally(() => {
          this.loading = false

          setTimeout(() => {
            this.disabled = false
          }, 10 * 1000)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
</style>
